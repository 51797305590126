<template>
  <ConfirmPopup></ConfirmPopup>
  <Card>
    <template #content>
      <div class="p-p-3">
        <DataTable
          filterDisplay="menu"
          sortMode="multiple"
          responsiveLayout="scroll"
          v-model:filters="filters"
          :alwaysShowPaginator="false"
          :lazy="true"
          :loading="loading"
          :value="forms"
          :totalRecords="count"
          :paginator="true"
          :rows="10"
          @filter="$emit('filter', $event)"
          @page="$emit('page', $event)"
        >
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center p-flex-sm-row">
              <h4 class="p-mb-0">EOD Builder</h4>
              <Button
                type="button"
                icon="pi pi-plus"
                label="New EOD"
                class="p-button-outlined p-mb-2"
                @click="$emit('show-editor')"
              />
            </div>
          </template>
          <template #empty>
            <p class="p-text-center">No records found</p>
          </template>
          <Column field="name" header="Name" :showFilterMatchModes="false">
            <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
            </template>
          </Column>
          <Column field="created_by" header="Created by" :showFilterMatchModes="false">
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by Creator"
              />
            </template>
          </Column>
          <Column field="updated_by" header="Modified by" :showFilterMatchModes="false">
            <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter email" />
            </template>
          </Column>
          <Column field="task.title" header="Task" :showFilterMatchModes="false">
            <template #filter="{ filterModel }">
              <AutoComplete
                v-model="filterModel.value"
                forceSelection
                :optionLabel="getOptionLabel"
                :delay="500"
                :suggestions="taskSuggestions"
                @complete="handleFetchSuggestions($event)"
                emptySearchMessage="No match found"
                placeholder="Enter Time Doctor task name"
              />
            </template>
            <template #body="{ data }">
              <div>
                <Chip
                  class="p-m-1"
                  v-for="task in data.tasks"
                  :key="task.id"
                  :label="`${task.project_name} - ${task.name}`"
                />
              </div>
            </template>
          </Column>
          <Column header="Fields">
            <template #body="{ data }">{{ data?.fields?.length || 0 }}</template>
          </Column>
          <Column headerStyle="min-width: 7rem" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
              <Button class="p-button-text p-button-rounded" icon="pi pi-pencil" @click="$emit('edit', data)" />
              <Button
                class="p-button-text p-button-rounded p-button-danger"
                icon="pi pi-trash"
                @click="handleFormDelete($event, data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </Card>
</template>

<script>
import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from 'primevue/useconfirm'
import useForm from '../../hooks/useForm'

export default {
  name: 'FormList',
  emits: ['delete', 'edit', 'filter', 'page', 'show-editor'],
  props: {
    count: { type: Number, required: true },
    forms: { type: Array, required: true },
    loading: { type: Boolean, default: true }
  },
  setup(props, { emit }) {
    const confirm = useConfirm()
    const { fetchTasks } = useForm()

    const filters = ref({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS, query: 'name' },
      created_by: { value: null, matchMode: FilterMatchMode.CONTAINS, query: 'created_by' },
      updated_by: { value: null, matchMode: FilterMatchMode.CONTAINS, query: 'updated_by' },
      'task.title': { value: null, matchMode: FilterMatchMode.CONTAINS, query: 'task' }
    })
    const taskSuggestions = ref([])

    const handleFormDelete = (event, id) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Do you want to delete this record?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          emit('delete', id)
        }
      })
    }

    const handleFetchSuggestions = async (e) => {
      const resp = await fetchTasks({ name: e.query.trim() })
      taskSuggestions.value = resp.data
    }

    const getOptionLabel = (data) => {
      let label = ''
      if (data?.division) label += `${data.division} - `
      if (data?.project_name) label += `${data.project_name} - `
      if (data?.name) label += data.name
      return label
    }

    return {
      filters,
      handleFetchSuggestions,
      handleFormDelete,
      taskSuggestions,
      getOptionLabel
    }
  }
}
</script>

<style scoped></style>

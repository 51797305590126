<template>
  <!-- Preview -->
  <FormPreview v-if="showPreview" :title="formData.name" :fields="formData.fields" @close="showPreview = false" />

  <!-- Field Editor -->
  <FieldEditor
    v-if="showFieldEditor"
    :initialData="initialFieldEditorData"
    :floating="isFieldFloating"
    @close="handleCloseFieldEditor"
    @field-save="handleFieldSave"
  />

  <!-- Main Editor -->
  <ModalEditor
    title="EOD Editor"
    :visible="!showFieldEditor && !showPreview"
    :closeButton="true"
    :showConfirmClose="formUpdated"
    @close="$emit('close')"
  >
    <template #content>
      <LabeledField
        data-test="form-title"
        id="form-title"
        class="p-col-12"
        label="Focus Name"
        :autoLayout="false"
        :showHelp="v?.name?.$invalid && v?.name?.$dirty"
        :errors="v.name.$silentErrors"
      >
        <InputText
          data-test="form-title-input"
          placeholder="Enter EOD name"
          v-model="formData.name"
          @blur="v.name.$touch()"
        />
      </LabeledField>
      <LabeledField id="form-overview" class="p-col-12" label="Overview" :autoLayout="false">
        <Textarea rows="4" v-model="formData.overview" />
      </LabeledField>
      <LabeledField id="form-desired-outcome" class="p-col-12" label="Desired Outcome" :autoLayout="false">
        <Textarea rows="4" v-model="formData.desired_outcome" />
      </LabeledField>
      <LabeledField id="form-success-measurement" class="p-col-12" label="Measurement of Success" :autoLayout="false">
        <Textarea rows="4" v-model="formData.success_measurement" />
      </LabeledField>
      <LabeledField
        data-test="form-td-task"
        id="form-task"
        class="p-col-12"
        label="Task"
        :autoLayout="false"
        :showHelp="v.tasks.$invalid && v.tasks.$dirty"
        :errors="v.tasks.$silentErrors"
      >
        <AutoComplete
          data-test="form-td-task-input"
          placeholder="Try typing job/focus name"
          optionDisabled="eod_form"
          v-model="formData.tasks"
          :field="getOptionLabel"
          :multiple="true"
          :suggestions="tdTasks"
          @complete="handleSearchTask($event)"
          @blur="v.tasks.$touch()"
          forceSelection
        />
      </LabeledField>
      <LabeledField id="form-emoji-code" class="p-col-12" label="Emoji Code" :autoLayout="false">
        <InputText placeholder="Enter emoji code" v-model="formData.emoji.code" />
      </LabeledField>
      <LabeledField
        id="form-emoji-url"
        class="p-col-12"
        label="Emoji URL"
        :autoLayout="false"
        :showHelp="v?.emoji.url?.$invalid && v?.emoji.url?.$dirty"
        :errors="v.emoji.url.$silentErrors"
      >
        <InputText placeholder="Enter emoji url" v-model="formData.emoji.url" @blur="v.emoji.url.$touch()" />
      </LabeledField>
      <ScrollPanel v-if="formData.fields?.length" class="p-col-12 custompanel" style="width: 100%; max-height: 300px">
        <FieldList class="p-mb-2" v-model="formData.fields" item-key="id" @sort="handleFieldSort">
          <template #item="{ element }">
            <FieldListItem
              :field="element"
              @delete="handleFieldDelete(element.id)"
              @edit="handleFieldEditPrep(element.id)"
            ></FieldListItem>
          </template>
        </FieldList>
      </ScrollPanel>
    </template>
    <template #footer>
      <Button
        data-test="button-add-field"
        class="p-button-text p-button-secondary"
        label="Add Field"
        :disabled="useFormLoading"
        @click="showFieldEditor = true"
      />
      <Button
        data-test="button-preview"
        v-if="formData.fields?.length"
        class="p-button-secondary"
        label="Preview"
        :disabled="useFormLoading"
        @click="showPreview = true"
      />
      <Button
        v-if="mode === EDITOR_MODE.CREATE"
        data-test="button-form-save"
        label="Save"
        :disabled="v.$invalid"
        :loading="useFormLoading"
        @click="handleSubmit"
      />
      <Button
        v-else
        data-test="button-form-update"
        label="Update"
        :disabled="v.$invalid || !formUpdated"
        :loading="useFormLoading"
        @click="handleSubmit"
      />
    </template>
  </ModalEditor>
</template>

<script setup>
import { computed, provide, reactive, ref, watch } from 'vue'

import FieldList from 'vuedraggable'
import { cloneDeep, isEqual, sortBy } from 'lodash-es'
import { minLength, required, url } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import { useVuelidate } from '@vuelidate/core'

import FieldEditor from './FieldEditor.vue'
import FieldListItem from './FieldListItem.vue'
import FormPreview from './FormPreview.vue'
import ModalEditor from '../ModalEditor.vue'
import useForm from '../../hooks/useForm'
import { handleHttpError } from '../../utils/utilities'
import { EDITOR_MODE, KPI_EDITOR_INITIAL_STATE } from '../../definitions'

const emit = defineEmits(['close', 'submitted'])
const props = defineProps({
  initialData: { type: Object, required: false }
})

// hooks
const toast = useToast()
const { createForm, error: useFormError, fetchTasks, loading: useFormLoading, updateForm } = useForm()

// data
let mode = EDITOR_MODE.CREATE
const tasks = ref([])
const fields = ref([])
const formData = reactive({ ...KPI_EDITOR_INITIAL_STATE, tasks, fields, emoji: { ...KPI_EDITOR_INITIAL_STATE.emoji } })
const tdTasks = ref([])
if (props.initialData) {
  mode = EDITOR_MODE.UPDATE
  Object.assign(formData, cloneDeep(props.initialData))
  fields.value = sortBy(fields.value, ['priority'])
}
const cacheData = { ...formData, fields: cloneDeep(fields.value), emoji: cloneDeep(formData.emoji) }
// FieldEditor data
const showFieldEditor = ref(false)
const initialFieldEditorData = ref(null)
// Preview
const showPreview = ref(false)

const isFieldFloating = computed(() => {
  if (initialFieldEditorData.value === null) return true
  const cachedIds = cacheData.fields?.map((f) => f.id)
  if (!cachedIds?.length) return true

  return !cachedIds?.includes(initialFieldEditorData.value?.id)
})

// computed properties
const formUpdated = computed(() => !isEqual(formData, cacheData))
const formHasParentField = computed(() => fields.value.filter((el) => el.is_parent).length > 0)

watch(useFormError, (value) => {
  handleHttpError(value, toast)
})
watch(showFieldEditor, (value) => {
  if (!value) initialFieldEditorData.value = null
})

// provide / inject
const nextFieldDisplayPriority = computed(() => {
  const priorityValues = fields.value.map((el) => el.priority)
  return priorityValues.length ? priorityValues.reduce((prev, curr) => (prev < curr ? curr : prev)) + 1 : 1
})
provide('nextFieldDisplayPriority', nextFieldDisplayPriority)
provide('formHasParentField', formHasParentField)

// methods
const handleCloseFieldEditor = () => {
  initialFieldEditorData.value = null
  showFieldEditor.value = false
}

const handleFieldEditPrep = (id) => {
  const field = fields.value.find((el) => el.id === id)
  initialFieldEditorData.value = { ...field }
  showFieldEditor.value = true
}

const handleFieldSort = () => {
  fields.value.forEach((field, index) => {
    if (field.is_parent) return
    field.priority = index + 1
  })
  fields.value = sortBy(fields.value, ['priority'])
}

const handleFieldDelete = (id) => (fields.value = fields.value.filter((el) => el.id !== id))

const handleFieldSave = (mode, data) => {
  if (mode === EDITOR_MODE.UPDATE) {
    const field = fields.value.find((el) => el.id === data.id)
    Object.assign(field, data)
  } else {
    fields.value.push({ ...data })
  }
  fields.value = sortBy(fields.value, ['priority'])
  showFieldEditor.value = false
}

const handleSearchTask = async (event) => {
  // Drop down options filtering
  const params = {}
  const name = event.query.trim().toLowerCase()
  if (name) {
    params.name = name
  }
  const resp = await fetchTasks(params)
  tdTasks.value = resp.data
}

const handleSubmit = async () => {
  // prepare data to feed to the endpoint
  const data = { ...formData, tasks: tasks.value.map((task) => task.id) }
  if (mode === EDITOR_MODE.UPDATE) {
    await updateForm(data)
  } else {
    await createForm(data)
  }
  if (useFormError.value) return
  emit('submitted', mode)
}

const getOptionLabel = (data) => {
  let label = ''
  if (data?.division) label += `${data.division} - `
  if (data?.project_name) label += `${data.project_name} - `
  if (data?.name) label += data.name
  return label
}

// Validation stuff
const rules = computed(() => ({
  name: { required },
  tasks: { required, minLength: minLength(1) },
  fields: { required, minLength: minLength(1) },
  emoji: { url: { url } }
}))
const v = useVuelidate(rules, formData)
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.8rem;
}
.field-list__container {
  max-height: 400px;
  margin: 0px 0px !important;
}
::v-deep(.p-scrollpanel) {
  &.custompanel {
    .p-scrollpanel-content {
      padding: 0 15 15px 0;
    }
  }
}
</style>

import { parsePage } from '../utils/utilities'
import useHttp from './useHttp'

export default () => {
  const http = useHttp()

  const createForm = async (data) => await http.exec({ method: 'POST', url: '/forms/eod-forms/', data })

  const deleteForm = async (id) => await http.exec({ method: 'DELETE', url: `/forms/eod-forms/${id}/` })

  const fetchForms = async (params) => {
    const resp = await http.exec({ method: 'GET', url: '/forms/eod-forms/', params })
    return !http.error.value ? { data: parsePage(resp.data), params } : {}
  }

  const updateForm = async (data) => await http.exec({ method: 'PUT', url: `/forms/eod-forms/${data.id}/`, data })

  const fetchTasks = async (params) => await http.exec({ method: 'GET', url: '/forms/eod-forms/tasks/', params })

  return { ...http, createForm, deleteForm, fetchForms, fetchTasks, updateForm }
}

<template>
  <div>
    <FormList
      :count="fetchData.count"
      :forms="fetchData.results"
      :loading="loading"
      @delete="handleFormDelete"
      @edit="handleFormEditPrep"
      @filter="handleListFilter"
      @page="handleListPagination"
      @show-editor="showEditor = true"
    />
  </div>
  <FormEditor
    v-if="showEditor"
    :initialData="initialFormEditorData"
    @close="handleCloseEditor"
    @submitted="handleFormSubmitted"
  />
</template>

<script>
import { computed, reactive, ref, watch } from 'vue'

import { useToast } from 'primevue/usetoast'

import FormEditor from '../../components/forms/FormEditor.vue'
import FormList from '../../components/forms/FormList.vue'
import useForm from '../../hooks/useForm'
import { handleHttpError, parseFilters } from '../../utils/utilities'

export default {
  name: 'Forms',
  components: { FormEditor, FormList },
  setup() {
    const toast = useToast()
    const { deleteForm, error: useFormError, fetchForms, loading } = useForm()

    // data
    const forms = ref([])
    const fetchData = reactive({
      count: 0,
      current: null,
      next: null,
      previous: null,
      results: [],
      params: {}
    })
    const showEditor = ref(false)
    const initialFormEditorData = ref(null)

    watch(useFormError, (value) => {
      handleHttpError(value, toast)
    })

    // methods
    const handleCloseEditor = () => {
      initialFormEditorData.value = null
      showEditor.value = false
    }

    const handleFormDelete = async (id) => {
      await deleteForm(id)
      if (useFormError.value) return

      const { count, current } = fetchData
      let page = (count - 1) % 10 ? current : current - 1
      page = page > 0 ? page : 1 // Do not allow 0 page value
      const { data, params } = await fetchForms({ ...fetchData.params, page })
      if (useFormError.value) return

      Object.assign(fetchData, { ...data, params })
      toast.add({ severity: 'success', summary: 'Success', detail: 'EOD deleted', life: 3000 })
    }

    const handleFormEditPrep = (data) => {
      initialFormEditorData.value = { ...data }
      showEditor.value = true
    }

    const handleFormSubmitted = async (mode) => {
      showEditor.value = false

      // fetchData.params -- use previous fetch parameters
      const { data } = await fetchForms(fetchData.params)
      if (useFormError.value) return
      Object.assign(fetchData, data)

      let toastMessage
      if (mode === 'update') {
        initialFormEditorData.value = null
        toastMessage = 'EOD updated'
      } else {
        toastMessage = 'New EOD added'
      }
      toast.add({ severity: 'success', summary: 'Success', detail: toastMessage, life: 3000 })
    }

    const handleListFilter = async (event) => {
      const filters = parseFilters(event.filters)
      if (filters.task) {
        filters.task = filters.task.id
      }
      const { data, params } = await fetchForms({ ...filters })
      if (useFormError.value) return
      Object.assign(fetchData, { ...data, params })
    }

    const handleListPagination = async (event) => {
      const { page } = event
      const filters = parseFilters(event.filters)
      const { data, params } = await fetchForms({ page: page + 1, ...filters }) // DataTable page is zero indexed
      if (useFormError.value) return
      Object.assign(fetchData, { ...data, params })
    }

    ;(async () => {
      const { data } = await fetchForms()
      if (!useFormError.value) Object.assign(fetchData, data)
    })()

    const computedForms = computed(() =>
      forms.value.map((form) => ({
        ...form,
        title: form.name,
        taskName: form.task_name
      }))
    )

    return {
      computedForms,
      fetchData,
      forms,
      handleCloseEditor,
      handleFormDelete,
      handleFormSubmitted,
      handleFormEditPrep,
      handleListFilter,
      handleListPagination,
      initialFormEditorData,
      loading,
      showEditor
    }
  }
}
</script>

<style scoped></style>
